import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useContext, useEffect, useState } from "react";
import generateTableConfigs from "../../../../utils/TableConfigGenerator";
import useEditorControl from "../../../../custom_hooks/complex/useEditorControl";
import { addDays, format, set, subDays } from "date-fns";
import { Divider } from "primereact/divider";
import CustomerSelectorDialog from "../../../../dialogs/selectors/CustomerSelectorDialog";
import BCSelectorDialog from "../../../../dialogs/selectors/BCSelectorDialog";
import DoctorSelectorDialog from "../../../../dialogs/selectors/DoctorSelectorDialog";
import NurseSelectorDialog from "../../../../dialogs/selectors/NurseSelectorDialog";
import TherapistSelectorDialog from "../../../../dialogs/selectors/TherapistSelectorDialog";
import generateDefaultValue from "../../../../utils/GenerateDefaultValue";
import CRMOptionSelectorDialog from "../../../../dialogs/selectors/CRMOptionSelectorDialog";
import generateRandomMinusId from "../../../../utils/GenerateRandomMinusId";
import ProductSelectorDialog from "../../../../dialogs/selectors/ProductSelectorDialog";
import { confirmDialog } from "primereact/confirmdialog";
import UiContext from "../../../../store/ui-context";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";

const configs = [
  // add_configs
  {
    name: "Customer",
    field: "customer_id",
    type: "object",
    object_name: "data_customer",
    object_field: "name",
    table: true,
  },
  {
    name: "Description",
    field: "description",
    type: "text_plain",
    table: true,
    filter: true,
  },
  {
    name: "Beauty Consultant",
    field: "bc_id",
    type: "object",
    object_name: "data_bc",
    object_field: "name",
    table: true,
  },
  {
    name: "Appointment Date",
    field: "transaction_date",
    type: "date",
    align: "center",
    table: true,
    editor: true,
    format: "dd/MM/yyyy",
    prime_format: "d MM yy",
    // show_time: true,
    min: subDays(new Date(), 1),
    max: addDays(new Date(), 365),
  },

  {
    name: "Doctor",
    field: "doctor_id",
    type: "object",
    object_name: "data_doctor",
    object_field: "name",
    table: true,
  },
  {
    name: "Nurse 1",
    field: "nurse_1_id",
    type: "object",
    object_name: "data_nurse_1",
    object_field: "name",
    table: true,
  },
  {
    name: "Nurse 2",
    field: "nurse_2_id",
    type: "object",
    object_name: "data_nurse_2",
    object_field: "name",
    table: true,
  },
  {
    name: "Nurse 3",
    field: "nurse_3_id",
    type: "object",
    object_name: "data_nurse_3",
    object_field: "name",
    table: true,
  },
  {
    name: "Therapist 1",
    field: "therapist_1_id",
    type: "object",
    object_name: "data_therapist_1",
    object_field: "name",
    table: true,
  },
  {
    name: "Therapist 2",
    field: "therapist_2_id",
    type: "object",
    object_name: "data_therapist_2",
    object_field: "name",
    table: true,
  },
  {
    name: "Therapist 3",
    field: "therapist_3_id",
    type: "object",
    object_name: "data_therapist_3",
    object_field: "name",
    table: true,
  },
  {
    name: "Customer Request",
    field: "data_crm_options",
    type: "list_object",
    columns: [
      {
        name: "Description",
        field: "name",
        type: "text_plain",
        editable: false,
      },
    ],
    width: "30rem",
    table: false,
    filter: false,
    editor: true,
  },
  {
    name: "BC Recommandation",
    field: "data_products",
    type: "list_object",
    columns: [
      {
        name: "Description",
        field: "data_product.name",
        type: "text_plain",
        editable: false,
      },
    ],
    width: "30rem",
    table: false,
    filter: false,
    editor: true,
  },
];

const editorConfigs = configs;
const apiName = "masterdata/employee";
let apiSuffix;
const customElements = [];
const isForm = false;

// const times = [
//   { name: "Not Selected", value: 0 },
//   { name: "10.00 AM", value: 10 },
//   { name: "11.00 AM", value: 11 },
//   { name: "12.00 PM", value: 12 },
//   { name: "13.00 PM", value: 13 },
//   { name: "14.00 PM", value: 14 },
//   { name: "15.00 PM", value: 15 },
//   { name: "16.00 PM", value: 16 },
//   { name: "17.00 PM", value: 17 },
//   { name: "18.00 PM", value: 18 },
// ];

const AppointmentEditorDialog = (props) => {
  const { title, onSave, id, loading, onRemove, onDone, data, date } = props;
  const [hourValue, setHourValue] = useState(0);
  const [minuteValue, setMinuteValue] = useState(0);
  // const [selectedTime, setSelectedTime] = useState(null);

  const uiContext = useContext(UiContext);

  const {
    // methods
    // onSave,
    onFetchData,
    handleInputChange,
    handleMultipleInputChange,
    // states
    value,
    setValue,
    setError,
    // loading,
    elements,
    //utils
    validator,
    generateSelectorDialog,
  } = useEditorControl({
    configs,
    isForm,
    apiName,
    apiSuffix,
    customElements,
  });

  useEffect(() => {
    if (props.visible) {
      if (id <= 0) {
        setHourValue(10);
        setMinuteValue(0);
        const defaultValue = generateDefaultValue({ tableConfigs: configs });
        if (date) {
          defaultValue.transaction_date = format(date, "yyyy-MM-dd HH:mm");
        }
        setValue(defaultValue);
      } else {
        if (data.transaction_date) {
          const transactionDate = new Date(data.transaction_date);
          setHourValue(transactionDate.getHours());
          setMinuteValue(transactionDate.getMinutes());
        }
      }
    }
  }, [props.visible]);

  useEffect(() => {
    if (data) {
      setValue(data);
      if (data.transaction_date) {
        const transactionDate = new Date(data.transaction_date);
        setHourValue(transactionDate.getHours());
        setMinuteValue(transactionDate.getMinutes());
      }
    }
  }, [id, data]);

  const handleChangeSelectedTime = ({ hour, minute }) => {
    // setSelectedTime(time);
    const newDate = new Date(value.transaction_date);
    if (hour) {
      newDate.setHours(hour, newDate.getMinutes(), 0, 0);
    }
    if (minute) {
      newDate.setHours(newDate.getHours(), minute, 0, 0);
    }
    setValue({
      ...value,
      transaction_date: format(newDate, "yyyy-MM-dd HH:mm:ss"),
    });
  };

  const handleOnRemove = () => {
    if (!value.id || value.id <= 0) {
      uiContext.showErrorMsg("Error", "Appointment is not saved yet");
      return;
    }

    confirmDialog({
      acceptClassName: "p-button-danger",
      message: "Are you sure you want to remove this appointment?",
      header: "Remove Appointment",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        if (onRemove) onRemove(value);
      },
    });
  };

  const handleOnDone = () => {
    if (!value.id || value.id <= 0) {
      uiContext.showErrorMsg("Error", "Appointment is not saved yet");
      return;
    }

    confirmDialog({
      message:
        "You are about to change appointment status to done. Are you sure?",
      header: "Confirm Appointment Done",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        if (onDone) onDone(value);
      },
    });
  };

  // add_generate_selector_dialog
  generateSelectorDialog({
    field: "customer_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <CustomerSelectorDialog
          single={true}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  generateSelectorDialog({
    field: "bc_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <BCSelectorDialog
          single={true}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  generateSelectorDialog({
    field: "bc_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <BCSelectorDialog
          single={true}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  generateSelectorDialog({
    field: "doctor_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <DoctorSelectorDialog
          single={true}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  generateSelectorDialog({
    field: "nurse_1_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <NurseSelectorDialog
          single={true}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  generateSelectorDialog({
    field: "nurse_2_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <NurseSelectorDialog
          single={true}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  generateSelectorDialog({
    field: "nurse_3_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <NurseSelectorDialog
          single={true}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  generateSelectorDialog({
    field: "therapist_1_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <TherapistSelectorDialog
          single={true}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  generateSelectorDialog({
    field: "therapist_2_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <TherapistSelectorDialog
          single={true}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  generateSelectorDialog({
    field: "therapist_3_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <TherapistSelectorDialog
          single={true}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  generateSelectorDialog({
    field: "data_crm_options",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <CRMOptionSelectorDialog
          single={true}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={(e, data) => {
            if (data && data.length > 0) {
              const dataSingle = data[0];
              const newData = {
                id: generateRandomMinusId(),
                crm_option_id: dataSingle.id,
                name: dataSingle.name,
                duration: dataSingle.duration,
              };
              onConfirm(e, [newData]);
            }
          }}
        />
      );
    },
  });

  generateSelectorDialog({
    field: "data_products",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <ProductSelectorDialog
          single={true}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={(e, data) => {
            if (data && data.length > 0) {
              const dataSingle = data[0];
              const newData = {
                id: generateRandomMinusId(),
                product_id: dataSingle.id,
                duration: dataSingle.duration,
                data_product: dataSingle,
              };
              onConfirm(e, [newData]);
            }
          }}
        />
      );
    },
  });

  const dialogFooter = (
    <div className="row-between-center w-100">
      <Button
        className="w-[16rem]"
        label="Remove Appointment"
        loading={loading}
        severity="danger"
        icon="pi pi-exclamation-triangle"
        onClick={handleOnRemove}
      />
      <div className="row-end-center w-100">
        <Button
          // className="w-full"
          label="Close"
          loading={loading}
          icon="pi pi-arrow-left"
          onClick={(e) => {
            e.preventDefault();
            props.onHide();
          }}
        />
        <Button
          // className="w-full"
          severity="success"
          loading={loading}
          label="Done"
          icon="pi pi-check"
          onClick={handleOnDone}
        />
        <Button
          // className="w-full"
          severity="success"
          loading={loading}
          label="Save"
          icon="pi pi-save"
          onClick={(e) => {
            if (onSave) onSave(value);
          }}
        />
      </div>
    </div>
  );

  return (
    <Dialog
      visible={props.visible}
      header={title}
      modal
      className={`p-fluid w-[62rem] min-h-[33rem]`}
      footer={dialogFooter}
      onHide={props.onHide}
      contentStyle={{ paddingBottom: "0px" }}
    >
      <div className="row-start-start w-full gap-4 pb-4">
        <div className="col-start-center w-full gap-2">
          {elements.customer_id}
          <div className="row-start-start w-full gap-4">
            {elements.bc_id}
            {elements.doctor_id}
          </div>
          {elements.description}
          <div className="row-start-end w-full gap-4">
            {elements.transaction_date}
            <div className="row-start-center gap-2">
              <div className="col-start-start gap-1">
                <span className="font-semibold text-slate-500 text-sm pl-1">
                  Hour
                </span>
                <InputNumber
                  className="w-[5.7rem]"
                  showButtons
                  buttonLayout="horizontal"
                  value={hourValue}
                  onChange={(e) => {
                    setHourValue(e.value);
                    handleChangeSelectedTime({ hour: e.value });
                  }}
                  mode="decimal"
                  min={1}
                  max={18}
                  pt={{
                    incrementButton: { className: "max-w-[1.4rem]" },
                    decrementButton: { className: "max-w-[1.4rem]" },
                  }}
                />
              </div>
              <span className="pt-4"> : </span>
              <div className="col-start-start gap-1">
                <span className="font-semibold text-slate-500 text-sm pl-1">
                  Minute
                </span>
                <InputNumber
                  className="w-[5.7rem]"
                  showButtons
                  buttonLayout="horizontal"
                  value={minuteValue}
                  onChange={(e) => {
                    setMinuteValue(e.value);
                    handleChangeSelectedTime({ minute: e.value });
                  }}
                  mode="decimal"
                  min={0}
                  max={59}
                  pt={{
                    incrementButton: { className: "max-w-[1.4rem]" },
                    decrementButton: { className: "max-w-[1.4rem]" },
                  }}
                />
              </div>
            </div>

            {/* <Dropdown
              className="w-[15rem] mb-[0.1rem]"
              value={selectedTime}
              onChange={(e) => handleChangeSelectedTime(e.value)}
              options={times}
              optionValue="value"
              optionLabel="name"
              placeholder="Select a Time"
            /> */}
          </div>
          <div className="row-start-start w-full gap-4">
            <div className="col-start-start w-full gap-2">
              {elements.nurse_1_id}
              {elements.nurse_2_id}
              {elements.nurse_3_id}
            </div>
            <div className="col-start-start w-full gap-2">
              {elements.therapist_1_id}
              {elements.therapist_2_id}
              {elements.therapist_3_id}
            </div>
          </div>
        </div>
        <Divider layout="vertical" className="m-0 h-[30rem]" />
        <div className="col-start-center w-full gap-4">
          {elements.data_crm_options}
          {elements.data_products}
        </div>
      </div>
    </Dialog>
  );
};
export default AppointmentEditorDialog;
