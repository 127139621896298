import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useContext, useState } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import AddTotalDiscountDialog from "../../../../../dialogs/AddTotalDiscountDialog";
import SalesNoteDialog from "../sales-dialogs/SalesNoteDialog";
import UiContext from "../../../../../store/ui-context";

const InvoiceControlSection = (props) => {
  const {
    width,
    data,
    onChangeSalesDiscount,
    onVoidSales,
    loading,
    onChangeNote,
  } = props;

  const uiContext = useContext(UiContext);
  const [visibleTotalDiscountDialog, setVisibleTotalDiscountDialog] =
    useState(false);
  const [visibleNoteDialog, setVisibleNoteDialog] = useState(false);

  const handleClickDiscount = (salesItemId) => {
    if (data.customer_id) {
      setVisibleTotalDiscountDialog(true);
    } else {
      uiContext.showErrorMsg("Error", "Please select a customer first");
    }
  };

  const handleVoidSales = () => {
    if (data.customer_id) {
      confirmDialog({
        message: "Are you sure you want to void this sales?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          onVoidSales();
        },
      });
    } else {
      uiContext.showErrorMsg("Error", "Please select a customer first");
    }
  };

  const handleClickChangeNote = () => {
    if (data.customer_id) {
      setVisibleNoteDialog(true);
    } else {
      uiContext.showErrorMsg("Error", "Please select a customer first");
    }
  };

  return (
    <div className={`col-start-center ${width} gap-1`}>
      <AddTotalDiscountDialog
        visible={visibleTotalDiscountDialog}
        onHide={(e) => setVisibleTotalDiscountDialog(false)}
        onConfirm={(e, discount) => {
          if (discount && discount.length > 0) {
            onChangeSalesDiscount({ discount });
            setVisibleTotalDiscountDialog(false);
          }
        }}
      />

      <SalesNoteDialog
        visible={visibleNoteDialog}
        onHide={(e) => setVisibleNoteDialog(false)}
        data={data.notes}
        onConfirm={(note) => {
          onChangeNote({ note });
          setVisibleNoteDialog(false);
        }}
        loading={loading}
      />

      <ConfirmDialog
        pt={{
          acceptButton: { className: "p-button-danger" },
        }}
      />
      <div className={`row-center-center ${width} gap-1`}>
        <Button
          loading={loading}
          className="max-h-[2rem] bg-red-400 border-red-400"
          icon="pi pi-trash"
          label="Void Sales"
          onClick={(e) => {
            e.preventDefault();
            handleVoidSales();
          }}
        />
        <Button
          loading={loading}
          className="max-h-[2rem]"
          icon="pi pi-tag"
          label="Discount"
          onClick={(e) => {
            e.preventDefault();
            handleClickDiscount();
          }}
        />
        <Button
          loading={loading}
          className="max-h-[2rem]"
          icon="pi pi-pencil"
          label="Note"
          onClick={(e) => {
            e.preventDefault();
            handleClickChangeNote();
          }}
        />
      </div>
    </div>
  );
};

export default InvoiceControlSection;
